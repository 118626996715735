<template>
  <div id="email-events">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Email Events</h1>
      <b-form-group class="mb-4" @keydown.native.enter="search" @keydown.native.esc="clearQuery">
        <b-input-group>
          <b-form-input v-model="query" placeholder="Type to Search" />
          <b-input-group-append>
            <b-btn variant="primary" :disabled="!query" @click="search">OK</b-btn>
            <b-btn :disabled="!query" @click="clearQuery">Clear</b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-table
        ref="emailEvents"
        :empty-text="emptyText"
        show-empty
        responsive
        :items="emailEventProvider"
        :fields="fields"
        empty-filtered-text="No events matching entered email."
      >
        <template v-slot:cell(created)="row">{{ formatDateTimeToSec(row.value) }}</template>
        <template v-slot:cell(sent)="row">{{ formatDateTimeToSec(row.value) }}</template>
      </b-table>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#email-events label {
  font-weight: bold;
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "email-events",
  data() {
    return {
      title: "Email Events",
      defaultEmptyText: "Enter an email address to search events.",
      emptyText: this.defaultEmptyText,
      invoices: [],
      fields: [
        { key: "to", label: "To" },
        {
          key: "created",
          label: "Created",
          sortable: true
        },
        {
          key: "sent",
          label: "Sent",
          sortable: true
        },
        { key: "from", label: "From" },
        { key: "type", label: "Type" },
        { key: "subject", label: "Subject" }
      ],
      query: null
    };
  },
  mounted() {
    this.query = this.$route.params.emailAddress;
  },
  methods: {
    formatDateTimeToSec: FormatHelper.formatDateTimeToSec,
    emailEventProvider(ctx, callback) {
      this.emailEvents = [];

      if (this.query) {
        this.fetchData().then(() => {
          callback(this.emailEvents);
        });
      } else {
        callback([]);
      }

      return null;
    },
    async fetchData() {
      this.emptyText = "Please wait, loading...";
      const client = await ApiHelper.http();

      try {
        const response = await client.get(
          `${ApiHelper.endPoint()}email/events${this.getEmailEventParams()}`
        );

        Console.log(response);

        if (response.data.status === "success") {
          this.emptyText = "No Email Events found";
          this.emailEvents = response.data.events;
        } else {
          this.showWarning((this.emptyText = "There was a problem getting email events"));
        }
      } catch (err) {
        Console.error(err);

        this.showWarning((this.emptyText = "There was a problem getting email events"));
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    clearQuery() {
      this.query = "";
      this.clearData();
    },
    getEmailEventParams() {
      let params = ApiHelper.appendParam(params, "email", encodeURIComponent(this.query));

      return params;
    },
    clearData() {
      this.emptyText = this.defaultEmptyText;
      this.emailEvents = [];
    },
    async search() {
      this.clearData();

      this.$refs.emailEvents.refresh();
    }
  }
};
</script>


